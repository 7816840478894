@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


* {
	-ms-overflow-style: none;
}

::-webkit-scrollbar {
	display: none;
}
